<template>
  <div class="abouts">
    <Banner title="关于我们" hint="国内领先的电子认证服务和信息安全解决方案提供商">
      <img class="banner-bg" style="width: 635px ;height: 432px;" src="@/assets/image/aboutZx/aboutUs-banner-bg.png" alt="关于我们" srcset="">
    </Banner>
    <div class="contact-way main-box">
      <div>
        <div class="label">客服热线</div>
        400-030-2013
      </div>
      <div>
        <div class="label">传真</div>
        0531-88208901
      </div>
      <div>
        <div class="label">电子邮箱</div>
        zxca@zxca.cn
      </div>
      <div>
        <div class="label">联系人</div>
        孙女士/张先生
      </div>
    </div>
    <div class="introduce main-box">
      <div class="info">
        <div class="title">
          <div class="tt">ABOUT US</div>
          山东豸信认证服务 有限公司
          <div class="line"></div>
        </div>
        <div class="text">
          山东豸信认证服务有限公司是国内领先的电子认证服务和信息安全解决方案提供商，主要为云计算、物联网、新一代移动通信、下一代互联网等新一代信息技术应用领域提供电子认证服务、产品研发及可管理的信息安全服务。豸信CA具有国家工业和信息化部颁发的电子认证服务许可资质。<br><br>
          作为领先的电子认证解决方案提供商，公司遵照《中华人民共和国电子签名法》的要求和相关管理规定，为用户提供“全方位”电子认证解决方案，以及基于云计算的安全技术研发服务、安全集成服务以及信息安全咨询、规划、培训等全方位的信息安全服务，建立起覆盖全国的电子认证服务网络和较完善的电子认证产品体系。为电子政务、电子商务、社会事业信息化和企业信息化等的快速发展，构建安全、可靠的信任环境。应用领域覆盖政府、金融机构等，在云计算领域的市场占有率位居行业前列，建立了市场领先优势。
        </div>
      </div>
      <div class="intro">
        2013年10月公司注册成立以来，一直秉承“公平正义、明辨真伪”的原则。发展期间获得多项荣誉及资质证书，2015年6月荣获“电子认证服务使用密码许可证”；2016年6月获得“电子认证服务许可证”；2017年1月荣获“电子政务电子认证服务许可证”；2022年1月，公司成功更名为“山东豸信认证服务有限公司”。
      </div>
    </div>
    <div class="certification">
      <div class="module-title">资质证书</div>
      <div class="main-box">
        <div v-for="(item,index) in certification" :key="index">
          <div class="image"> <img :src="require(`@/assets/image/aboutZx/certification/certification${index+1}.png`)" alt="资质" srcset=""></div>
          <div>{{item}}</div>
        </div>
      </div>
    </div>
    <div class="module-title">联系地址</div>
    <div class="officeSpace">
      <div class="station">
        <img src="@/assets/image/aboutZx/officeSpace.png" alt="公司场地">
        <h2>山东豸信认证服务有限公司</h2>
        <p><span>公司地址：</span>山东省济南市历城区唐冶绿地汇中心23号楼101</p>
        <p><span>邮政编码：</span>250014</p>
        <p><span>服务投诉：</span>400-040-2013</p>
      </div>
      <div class="map" id="mapContainer"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      certification: [
        '电子认证服务许可证',
        '电子认证服务密码使用许可证',
        '电子政务电子认证服务许可',
        '企业营业执照',
        '软件企业证书',
        '软件产品证书',
        'ISO27001',
        '高新技术企业证书',
        '山东省优秀软件企业证书',
        'CMMI3证书（软件能力成熟度集成模型)',
        '9000质量管理体系认证',
        '20000服务管理体系'
      ],
      addressActive: 0
    }
  },
  mounted() {
    let script = document.createElement('script')
    script.type = 'text/javascript'
    script.src =
      '//webapi.amap.com/maps?v=1.3&key=a3b9c7defdef43bb29e8992487e8161a'
    document.body.appendChild(script)
    script.onload = () => {
      // eslint-disable-next-line no-undef
      let center = { lng: 117.223304, lat: 36.692868 }
      let features = [
        {
          icon: 'pot',
          color: 'red',
          name: '山东豸信认证服务有限公司',
          desc:
            '山东省济南市历城区唐冶绿地城市大脑数字产业小镇AD23号楼101（商墅）',
          lnglat: {
            Q: 36.692864303,
            R: 117.223326998,
            lng: 117.223304,
            lat: 36.692861
          },
          offset: { x: -9, y: -31 },
          type: 'Marker'
        }
      ]
      this.mapInit('mapContainer', center, features)
    }
  },
  methods: {
    mapInit(id, center, features) {
      // eslint-disable-next-line no-undef
      let _AMap = AMap
      let infoWindow
      let map
      let level = 16

      function mapFeatureClick(e) {
        if (!infoWindow) {
          infoWindow = new _AMap.InfoWindow({ autoMove: true, isCustom: false })
        }
        var extData = e.target.getExtData()
        infoWindow.setContent(
          "<div class='myinfowindow'><h5>" +
            extData.name +
            '</h5><div>' +
            extData.desc +
            '</div></div>'
        )
        infoWindow.open(map, e.lnglat)
      }
      function loadFeatures() {
        var icon = new _AMap.Icon({
          size: new _AMap.Size(20, 30), // 图标尺寸
          image: require('@/assets/image/aboutZx/mark_rs.png'), // Icon的图像
          imageSize: new _AMap.Size(20, 30) // 根据所设置的大小拉伸或压缩图片
        })
        for (var feature, data, i = 0, len = features.length; i < len; i++) {
          data = features[i]
          feature = new _AMap.Marker({
            map: map,
            position: new _AMap.LngLat(data.lnglat.lng, data.lnglat.lat),
            zIndex: 3,
            extData: data,
            offset: new _AMap.Pixel(data.offset.x, data.offset.y),
            title: data.name,
            icon: icon
          })
          if (feature) {
            _AMap.event.addListener(feature, 'click', mapFeatureClick)
          }
        }
      }

      map = new _AMap.Map(id, {
        center: new _AMap.LngLat(center.lng, center.lat),
        level: level,
        keyboardEnable: true,
        dragEnable: true,
        scrollWheel: true,
        doubleClickZoom: true
      })
      loadFeatures()

      map.on('complete', function() {
        map.plugin(['AMap.ToolBar', 'AMap.OverView', 'AMap.Scale'], function() {
          map.addControl(new _AMap.Scale())
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-box {
  height: 564px;
  width: 100%;
  background: linear-gradient(91deg, #2443d0 0%, #092ed9 100%);
  ::v-deep.text {
    color: #fff;
    .title {
      font-weight: normal;
    }
    .hint {
      color: #d0d4dc;
    }
  }
}
.contact-way {
  display: flex;
  margin-top: -57px;
  justify-content: space-between;
  margin-bottom: 178px;
  & > div {
    height: 114px;
    width: 100%;
    z-index: 9;
    margin-right: 23px;
    background: #f9fbff;
    background-image: url('~@/assets/image/aboutZx/contact-way-bg.png');
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 282px 85px;
    box-shadow: 0px 4px 10px 0px rgba(31, 37, 81, 0.11);
    border-radius: 4px;
    border: 2px solid #ffffff;
    padding: 34px 80px;
    font-size: 12px;
    color: #8d96a3;
    line-height: 22px;
    .label {
      font-size: 16px;
      color: #303b50;
      line-height: 21px;
      margin-bottom: 4px;
      font-weight: 700;
    }
  }
}
.introduce {
  margin-bottom: 160px;
  .info {
    display: flex;
    margin-bottom: 52px;
    .title {
      font-size: 48px;
      font-weight: bold;
      color: #343b56;
      line-height: 90px;
      width: 384px;
      margin-right: 168px;
      flex-shrink: 0;
      .tt {
        font-weight: normal;
        font-size: 21px;
        color: #4685f9;
        line-height: 27px;
        margin-bottom: 16px;
      }
      .line {
        width: 90px;
        height: 6px;
        background: #4685f9;
        margin-top: 32px;
      }
    }
    .text {
      font-size: 14px;
      color: #6c737b;
      line-height: 27px;
      letter-spacing: 2px;
    }
  }
  .intro {
    height: 338px;
    padding: 82px 52px;
    font-size: 16px;
    color: #b6bed1;
    line-height: 27px;
    letter-spacing: 2px;
    background-image: url('~@/assets/image/aboutZx/introduce-bg.png');
    background-size: 100% 100%;
  }
}
.module-title {
  font-size: 36px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #343b56;
  line-height: 48px;
  text-align: center;
  margin-bottom: 120px;
  &::before,
  &::after {
    content: '';
    width: 121px;
    height: 27px;
    background-image: url('~@/assets/image/aboutZx/certification-icon.png');
    background-size: 100% 100%;
    left: 10px;
    top: 0;
    display: inline-block;
    margin: 0 32px;
  }
  &::after {
    transform: rotate(180deg);
  }
}
.certification {
  background: #f9fbff;
  padding: 120px 0;
  margin-bottom: 160px;
  .main-box {
    display: flex;
    flex-wrap: wrap;
    & > div {
      width: 410px;
      height: 360px;
      background: linear-gradient(
        180deg,
        #d0dfff 0%,
        #f8fbff 30%,
        #f5f7ff 100%
      );
      box-shadow: 0px 4px 16px 0px rgba(31, 37, 81, 0.11);
      border-radius: 4px;
      border: 2px solid #ffffff;
      padding: 48px 50px 0 50px;
      text-align: center;
      font-size: 18px;
      color: #2a2b2c;
      line-height: 24px;
      letter-spacing: 2px;
      margin-bottom: 34px;
      margin-right: 34px;
      font-weight: 700;
      &:nth-child(3n) {
        margin-right: 0;
      }
      .image {
        width: 310px;
        height: 219px;
        margin-bottom: 32px;
        position: relative;
        img {
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.officeSpace {
  width: 1300px;
  height: 656px;
  margin: 0 auto 259px;
  position: relative;
  .map {
    width: 100%;
    height: 656px;
    margin: 6px;
    margin-left: 0;
    ::v-deep.icon {
      background: url(//a.amap.com/lbs-dev-yuntu/static/web/image/tools/creater/marker.png)
        no-repeat;
      height: 23px;
      width: 31px;
      background-position: -234px -55px;
    }
  }
}
.station {
  width: 374px;
  height: 459px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 9;
  overflow: hidden;
  img {
    width: 100%;
    height: 276px;
    margin-bottom: 10px;
  }
  h2 {
    font-size: 14px;
    color: #333333;
    line-height: 19px;
    padding: 0 16px;
    margin-bottom: 16px;
  }
  p {
    font-size: 14px;
    color: #333333;
    line-height: 19px;
    margin-bottom: 16px;
    padding: 0 16px;
    &:nth-last-child(3) {
      line-height: 24px;
    }
  }
  span {
    font-weight: 700;
  }
}
</style>
